import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  codeDataType,
  useManualServerSentEvents,
} from "../../hook/useManualServerSentEvents";
import Router, { useRouter } from "next/router";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import { accept } from "../UpLoad/UpLoad";
import http from "../../http";
import styles from "../../styles/chat.module.scss";
import TypingEffect from "../TypingEffect";
import { TreeData } from "../tree/CodeTree";
import ChatCreatContent from "./ChatCreatContent";
import ChatTextArea from "./ChatTextArea";
import BottomLayer from "./BottomLayer";
import { useUserInfo } from "../../context/ThemeContext";
import AssistantSelect from "../AssistantSelect";
import { RightOutlined } from "@ant-design/icons";
const ChatpdfBox = (
  props: {
    reLoadCancleChat: (id: string) => void;
    cancleChat: (id: string) => Promise<any>;
    getInfo: () => void;
    setLoading: (isLoading: boolean) => void;
    setSettingShow: (is: boolean) => void;
    setData: (
      treeData: TreeData[],
      codeData: codeDataType,
      isoutputing: boolean
    ) => void;
    threadId: string;
    visible: boolean;
  },
  ref: any
) => {
  const router = useRouter();
  const chatContainerRef = useRef({} as any);
  const autoScroll = useRef(true as boolean);
  const markdown = useRef("");
  const file_id = useRef("" as any);
  const thread_id = useRef("" as any);
  const stopEnter = useRef(false);
  const typeRef = useRef("" as any);
  const chatTextArea = useRef<any>();
  const [fileName, setFileName] = useState("");
  const [chatpdfTips, setChatpdfTips] = useState(true);
  const [upLoading, setUpLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [cancleTag, setCancleTag] = useState(false);
  const [stop, setStop] = useState(false);
  const [chatContent, setChatContent] = useState([] as any);
  const { userInfo, toggleUserInfo } = useUserInfo();
  const [assistantId, setAssistantId] = useState("0");
  useImperativeHandle(ref, () => {
    return {
      createNewChat,
      getChatLog,
      createThread,
    };
  });
  const tipList = [
    "根据我们前面的所有对话，总结并撰写新的review comment",
    "当前项目还处于原型开发阶段，无需考虑safety和security问题",
  ];
  const resetFn = () => {
    setStop(false);
    stopEnter.current = false;
    markdown.current = "";
  };
  const clearFileId = () => {
    file_id.current = null;
    setFileName("");
  };
  useEffect(() => {
    thread_id.current = props.threadId;
  }, [props.threadId]);

  const {
    messages,
    startFetching,
    stopFetching,
    setMessages,
    treeData,
    codeData,
    responseState,
    isoutputing,
  } = useManualServerSentEvents(
    "/api/v1/chat/chat",
    {
      file_id: file_id.current,
      thread_id: thread_id.current,
      message: markdown.current,
      assistant_id: assistantId,
    },
    clearFileId,
    setStop,
    setFlag,
    props.getInfo,
    resetFn
  );
  useEffect(() => {
    props.setData(treeData, codeData, isoutputing);
  }, [treeData, codeData, isoutputing]);

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
    // setAutoScroll(isAtBottom);
    autoScroll.current = isAtBottom;
  }, []);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer && !chatpdfTips) {
      chatContainer.addEventListener("scroll", handleScroll);

      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [chatpdfTips, handleScroll]);

  useEffect(() => {
    if (chatContainerRef.current && autoScroll.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [chatContent, autoScroll]); // 在 chatContent 或 autoScroll 变化时触发

  // Combine messages and replace '\n\n' with HTML line break '<br /><br />'
  const combinedMessages = useMemo(() => {
    // setStop(false)
    let str = "";
    // messages.map((message: any) => message.content ? str += message.content : null);
    chatContent[chatContent.length - 1] = {
      text: messages.value,
      user_type: "assistant",
      pending: messages.pending,
    };
    setChatContent([...chatContent]);
    // return str.replace(/\n\n/g, '<br /><br />');
    return str;
  }, [messages]);

  const giToBottom = (allways: boolean = false) => {
    if (chatContainerRef.current && (allways || autoScroll.current)) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTo({
        top: scrollHeight + 999, // 滚动到的垂直位置
        left: 0, // 滚动到的水平位置
        behavior: "smooth", // 平滑滚动
      });
    }
  };

  // 聊天
  const createChat = () => {
    if (!checkLogin()) {
      return;
    }
    //点击发送
    if (upLoading) {
      //上传过程中不能点击发送
      return;
    }
    if (flag) {
      setText("");
      ShowMessage.error(
        "您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅"
      );
      return;
    }
    if (markdown.current.trim() == "") {
      ShowMessage.error("请输入您需要解读的内容");
      return;
    }
    // 记录当前thread_id
    const ids = JSON.parse(localStorage.getItem("threadIds") || "[]");
    localStorage.setItem(
      "threadIds",
      JSON.stringify([...ids, thread_id.current])
    );

    typeRef.current && typeRef.current.setStopTimer();
    setChatpdfTips(false);
    setStop(true);
    // setAutoScroll(true);
    autoScroll.current = true;
    setChatContent([
      ...chatContent,
      { text: markdown.current, user_type: "user", file_name: fileName },
      { text: "loading", user_type: "assistant" },
    ]);
    startFetching({
      file_id: file_id.current,
      thread_id: thread_id.current,
      message: markdown.current,
      assistant_id: assistantId,
    });
    setText("");
    setCancleTag(false);
  };
  const keyupFun = (e: any) => {
    const { code, shiftKey } = e;
    if (code == "Enter" && !shiftKey && !e.nativeEvent.isComposing) {
      createChat();
    }
  };
  const uploadFile = (e: any) => {
    if (!checkLogin()) {
      return;
    }
    e.preventDefault();
    // 检查文件后缀是否合法
    const file = e.target.files?.[0];
    if (!file) {
      ShowMessage.error("请选择一个文件");
      return;
    }
    if (file.size > 220 * 1024 * 1024) {
      ShowMessage.error("最大只能上传220MB");
      return;
    }
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!accept.includes(`.${fileExtension}`)) {
      ShowMessage.error(`只能上传${accept.toString()}文件`);
      return;
    }

    setUpLoading(true);
    let formData = new FormData();
    setFileName(file.name);
    formData.append("file", e.target.files[0]);
    http
      .post(`/api/v1/chat/file?thread_id=${thread_id.current}`, formData)
      .then((res: any) => {
        if (res.data.openai_file_id) {
          ShowMessage.success("上传成功");
          file_id.current = res.data.openai_file_id;
        } else {
          ShowMessage.error("上传失败");
          clearFileId();
        }
        setUpLoading(false);
      })
      .catch(() => {
        ShowMessage.error("上传失败");
        clearFileId();
      })
      .finally(() => {
        setUpLoading(false);
      });
  };
  const checkLogin = () => {
    const token = localStorage.getItem("token");
    if (!userInfo.phone_number && !token) {
      Router.push("/login");
      return false;
    }
    return true;
  };

  const handleInputChange = (e: { target: any }) => {
    const textarea = e.target;
    markdown.current = textarea.value;
  };
  useEffect(() => {
    if (responseState === 500) {
      props.reLoadCancleChat(thread_id.current);
      setCancleTag(true); // 显示已取消
    }
  }, [responseState]);
  // 创建对话
  const createThread = () => {
    // debugger
    setStop(false);
    http
      .post(`/api/v1/chat/thread`)
      .then(
        (data: any) => {
          props.setLoading(false);
          //debugger
          // this.ControlMessage('加载完成', true);
          if (data.status) {
            if (data.code == 402) {
              setFlag(true);
              props.getInfo();
              return;
            }
            thread_id.current = data.data.thread_id;
            Router.push(`/?thread_id=${data.data.thread_id}`);
          } else {
            ShowMessage.error(data.message);
          }
        },
        (err) => {
          props.setLoading(false);
        }
      )
      .catch((res) => {});
  };
  // 获取聊天历史
  const getChatLog = async (threadId: any) => {
    Router.push(`/?thread_id=${threadId}`);
    thread_id.current = threadId;
    setText("");
    typeRef.current && typeRef.current.setStopTimer();
    setCancleTag(false);
    stopFetching();
    resetFn();
    props.setLoading(true);
    props.setSettingShow(false);
    http
      .get(`/api/v1/chat/chat_history/?thread_id=${threadId}`)
      .then((data: any) => {
        props.setLoading(false);
        if (data.status) {
          if (data.data.length == 0) {
            setChatpdfTips(true);
          } else {
            setChatpdfTips(false);
          }
          setChatContent(data.data);
        } else {
          ShowMessage.error(data.message);
        }
      });
  };
  const createNewChat = async () => {
    props.setLoading(true);
    setCancleTag(false);
    setChatpdfTips(true);
    setChatContent([]);
    setText("");
    // setMarkdown('')
    markdown.current = "";
    typeRef.current && typeRef.current.setStopTimer();
    props.cancleChat(thread_id.current);
    createThread();
  };
  const cancleFn = () => {
    //取消发送
    // debugger
    if (typeRef.current) {
      // 清除定时器，存在typeRef说明已经接口返回问题答案
      typeRef.current.clearTimeout();
    }
    if (chatContent[chatContent.length - 1].text === "loading") {
      //点击取消如果最后一条是内容还是loading，说明答案未返回，直接修改成取消
      chatContent[chatContent.length - 1].text = "已取消";
      setChatContent([...chatContent]);
    } else {
      chatContent[chatContent.length - 1].pending = false;
      setChatContent([...chatContent]);
    }
    props.cancleChat(thread_id.current);
    stopFetching();
    resetFn();
    setCancleTag(true);
  };
  const setText = (value: string) => {
    chatTextArea?.current?.setTextAreaValue(value);
  };
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        ShowMessage.success("复制成功");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const assistantChange = (str: string) => {
    setAssistantId(str);
  };
  const tipClick = (str: string) => {
    markdown.current = str;
    createChat();
  };

  return (
    <div
      className={styles.chatpdfBox}
      style={props.visible ? {} : { display: "none" }}
    >
      <div className={styles.chatHead}>
        <AssistantSelect assistantChange={assistantChange} />
      </div>
      {!chatpdfTips && (
        <div
          className={styles.chatpdfLine}
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {chatContent.map((item: any, index: number) => {
            return (
              <div key={index}>
                {item.user_type == "user" && item.text && (
                  <div
                    key={item.uuid ? item.uuid : index}
                    className={styles.chatpdfRow}
                  >
                    <div className={styles.chatpdfAsk}>
                      {item.file_name && (
                        <div className={styles.fileBox}>
                          <div className={styles.file}>
                            <div className={styles.fileBg} />
                            <span
                              className={styles.fileName}
                              title={item.file_name}
                            >
                              {item.file_name}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className={styles.askBox}>
                        <div className={styles.chatAskPdfContent}>
                          <pre>{item.text}</pre>
                        </div>
                        <div className={styles.chatpdfIcon}></div>
                      </div>
                    </div>
                  </div>
                )}
                {item.user_type == "assistant" && item.text && (
                  <div
                    key={item.uuid ? item.uuid : index}
                    className={styles.chatpdfRow}
                  >
                    <div className={styles.chatpdfIcon}></div>

                    {item.text != "当前对话不存在" &&
                      item.text != "订阅已到期" &&
                      item.text !=
                        "您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅" && (
                        <div className={styles.chatpdfContent}>
                          {item.text === "loading" &&
                            (!cancleTag ? (
                              <div className={styles.loadingContainer}>
                                <img
                                  style={{ width: "30px" }}
                                  src="/loading/loading2.gif"
                                  alt="loading"
                                />
                                <p className={styles.thinkingText}>
                                  Eagle正在思考中
                                </p>
                              </div>
                            ) : (
                              <p style={{ color: "red" }}>已取消</p>
                            ))}
                          {item.text !== "loading" && (
                            <TypingEffect
                              ref={typeRef}
                              stop={stop}
                              pending={item.pending}
                              setMessages={setMessages}
                              giToBottom={giToBottom}
                              setStop={resetFn}
                              thread_id={item.thread_id}
                              text={item.text}
                              speed={10}
                            />
                          )}
                          {!item.pending && item.text !== "loading" && (
                            <div className={styles.toolBtns}>
                              <div
                                className={styles.copy}
                                title="复制"
                                onClick={() => handleCopy(item.text)}
                              >
                                <div className={styles.copyIcon} />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {(item.text === "当前对话不存在" ||
                      item.text === "订阅已到期" ||
                      item.text ===
                        "您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅") && (
                      <div className={styles.chatpdfContent}>
                        <p style={{ color: "red" }}>{item.text}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}

          {!stop &&
            chatContent[chatContent.length - 1]?.user_type !== "user" &&
            !!chatContent[chatContent.length - 1]?.text && (
              <div className={styles.tipList}>
                {tipList.map((item) => {
                  return (
                    <div key={item} className={styles.tipItem}>
                      <div
                        className={styles.tipContent}
                        onClick={() => tipClick(item)}
                      >
                        {item}
                        <RightOutlined style={{ marginLeft: 10 }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      )}
      {/* 新建对话模块显示组件 */}
      {chatpdfTips && (
        <ChatCreatContent
          handleClick={(tip) => {
            setChatpdfTips(false);
            setText(tip);
            markdown.current = tip;
          }}
        />
      )}
      {/* 底部对话输入模块 */}
      <div className={styles.chatBottom}>
        <ChatTextArea
          ref={chatTextArea}
          fileName={fileName}
          onUpload={uploadFile}
          handleInputChange={handleInputChange}
          cancleFn={cancleFn}
          keyupFun={keyupFun}
          createChat={createChat}
          stop={stop}
          upLoading={upLoading}
        ></ChatTextArea>
        <BottomLayer
          className={styles.tips}
          suffix={"人工智能生成内容仅供参考，使用前请校验内容的正确性。"}
        />
      </div>
    </div>
  );
};

export default forwardRef(ChatpdfBox);
