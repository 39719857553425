// components/TypingEffect.js
import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import ReactMarkdown, { Options } from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/monokai-sublime.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-light.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-dark.css"; // 导入代码高亮的CSS样式
import hljs from "highlight.js";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // KaTeX 样式
import { Terminal } from "lucide-react";
import CopyButton from "./CopyButton";
import { useTheme } from "../context/ThemeContext";
import rehypeRaw from "rehype-raw";
import plantumlEncoder from "plantuml-encoder";
import { Image } from "antd";

const TypingEffect = (
  {
    text = "",
    pending = false,
    stop = true,
    speed = 20,
    thread_id = null,
    setStop = () => {},
    giToBottom = () => {},
    setMessages = (flage: any) => {},
  },
  ref: any
) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [stopTimer, setStopTimer] = useState(true); // 停止计时
  const { theme, toggleTheme } = useTheme();
  const timeout = useRef("" as any);
  const isRendered = useRef(false);
  const [imgLoadErr, setImgLoadErr] = useState(false);
  const umlRegex =
    /@start(uml|json|yaml|bnf|regex|salt|ditaa|gantt|chronology|mindmap|wbs|chen)[\s\S]*?@end\1/g;
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = theme === "light" ? "/github.css" : "/atom-one-dark.css"; // 你可以根据需要选择其他 dark 主题
    document.head.appendChild(link);
    hljs.highlightAll();
    return () => {
      document.head.removeChild(link);
    };
  }, [theme]);
  useEffect(() => {
    // 每次 messages 更新时，自动滚动到底部
    giToBottom();
  }, [text, displayedText]);
  useEffect(() => {
    if (isRendered.current) {
      setTimeout(() => {
        giToBottom();
      }, 400);
    }
  }, [isRendered]);

  // useEffect(() => {
  //   if(thread_id) return
  //   if (index < text.length&&stopTimer) {
  //     timeout.current = setTimeout(() => {
  //       setDisplayedText(displayedText + text[index]);
  //       setIndex(index + 1);
  //     }, speed);
  //     return () => clearTimeout( timeout.current);
  //   }else{
  //     setStop();
  //     clearTimeout( timeout.current)
  //    // setMessages('')
  //   }
  // }, [index, text, displayedText, speed]);
  useImperativeHandle(ref, () => {
    return {
      clearTimeout: () => {
        clearTimeout(timeout.current);
        setStopTimer(false);
      },
      setStopTimer: () => setStopTimer(true),
    };
  });
  const LinkRenderer = (data: any) => {
    const { href, children, ...args } = data;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...args}>
        {children}
      </a>
    );
  };
  const ImgRenderer = (data: any) => {
    const { src, children, ...args } = data;
    return <Image src={src} preview={!stop} alt="" />;
  };
  const ImageRender = (url: any, data: any, id: any) => {
    const urlList = JSON.parse(localStorage.getItem("sUrl") || "[]");
    if (!urlList?.includes(url)) {
      const fetchImage = async () => {
        try {
          isRendered.current = false;
          const response = await fetch(url, { method: "HEAD" });
          if (response.status !== 200) {
            setImgLoadErr(true);
          } else {
            localStorage.setItem("sUrl", JSON.stringify([...urlList, url]));
            isRendered.current = true;
          }
        } catch (error) {
          setImgLoadErr(true);
        }
      };
      fetchImage();
    }
    // 使用useMemo来记忆计算结果
    const memoizedValue = useMemo(
      () => (
        <Image
          key={id}
          src={url}
          preview={!stop}
          onError={() => {
            setImgLoadErr(true);
          }}
          alt=""
        />
      ),
      [url]
    );

    return memoizedValue;
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: true }]]}
      rehypePlugins={[
        rehypeRaw,
        rehypeKatex,
        // @ts-ignore
        [rehypeHighlight, { ignoreMissing: "true" }],
      ]}
      components={{
        pre: ({ children }) => <pre className="not-prose">{children}</pre>,
        a: LinkRenderer,
        img: ImgRenderer,
        code: (data: any) => {
          const { node, className, children, ...props } = data;
          const id = Math.random().toString(36).substr(2, 9);
          const match = /language-(\w+)/.exec(className || "");
          if (match?.length) {
            const isPlantUml =
              match.includes("language-plantuml") ||
              match.includes("plantuml") ||
              match.includes("plaintext") ||
              match.includes("language-plaintext");
            const umlMatches = umlRegex.test(children);
            if (isPlantUml && umlMatches && !imgLoadErr && !pending) {
              const match = children.match(umlRegex);
              const encoded = plantumlEncoder.encode(match?.[0] || "");
              const url = `https://eagle.capitalren.com/plant-uml/png/${encoded}`;
              return ImageRender(url, data, id);
            }
            return (
              <div
                key={id}
                className={`not-prose rounded-md  border-2  ${theme === 'dark' ? 'border-black' : 'border-gray'}`}
              >
                <div
                  className={`flex h-12 items-center justify-between px-4 ${theme == 'light' ? ' bg-zinc-100 dark:bg-zinc-900' : 'bg-zinc-900'}`}
                >
                  <div className="flex items-center gap-2">
                    <Terminal size={16} />
                    <p className={`text-sm ${theme == 'light' ? 'text-zinc-600 dark:text-zinc-400' : ''}`}>{node?.data?.meta}</p>
                  </div>
                  <CopyButton id={id} />
                </div>
                <div className={`overflow-x-auto`}>
                  <div
                    id={id}
                    className="p-4"
                    style={{ backgroundColor: theme == 'light'?"#D2D2D2":"#282828" }}
                  >
                    {children}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <code
                {...props}
                className={`not-prose rounded  px-1 ${
                  theme == "light"
                    ? " bg-gray-100 dark:bg-zinc-900"
                    : "bg-zinc-900"
                }`}
              >
                {children}
              </code>
            );
          }
        },
      }}
      className={`max-w-full overflow-x-auto prose ${
        theme === "light" ? "prose-zinc" : "prose-invert"
      }`}
    >
      {text}
    </ReactMarkdown>
  );
};

export default forwardRef(TypingEffect);
