import React, { useRef, useState } from "react";
import styles from "./upLoad.module.scss";
import { useTheme } from "../../context/ThemeContext";
import classNames from "classnames";
import { Tooltip } from "antd";
export const accept = [".zip", ".xlsx", ".xls", ".csv"];
export default function UpLoad(props: {
  disabled?: boolean;
  onChange: (e: any) => void;
}) {
  const { theme } = useTheme();
  const upLoad = useRef<any>();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const uploadFile = (e: any) => {
    e.preventDefault();
    e.target.value = "";
  };
  const handleClick = () => {
    upLoad?.current && upLoad?.current?.click();
  };
  const tooltipClose = () => {
    setTimeout(() => {
      setTooltipOpen(false);
    }, 100);
  };
  return (
    <Tooltip placement="top" title={"点击此处上传文件"} open={tooltipOpen}>
      <div
        className={styles.fileUpload}
        onClick={(e) => {
          tooltipClose();
        }}
        onMouseEnter={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={tooltipClose}
      >
        <div
          className={classNames(
            styles.upload,
            theme == "light" ? styles.icon_light : styles.icon
          )}
          onClick={handleClick}
        />
        <input
          disabled={!!props?.disabled}
          type="file"
          ref={upLoad}
          className={styles.file}
          onChange={uploadFile}
          accept={accept.join(",")}
        ></input>
      </div>
    </Tooltip>
  );
}
