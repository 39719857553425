import styles from "../../styles/chat.module.scss";
import { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import http from "../../http";
import CodeTree, { TreeData } from "../../components/tree/CodeTree";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import CodePreview from "../CodePreview/CodePreview";
import { useTheme, useUserInfo } from "../../context/ThemeContext";
import CodeModal from "../Modal/Modal";
import { Button, Tooltip } from "antd";
import { codeDataType } from "../../hook/useManualServerSentEvents";
export default function LeftPannel(props: {
  codeData: codeDataType;
  treeData: TreeData[];
  isoutputing: boolean;
  createNewChat: () => void;
  chatItemClick: (id: string) => void;
  settingClick: () => void;
  setLoading: (loading: boolean) => void;
}) {
  const router = useRouter();
  const {
    codeData,
    treeData,
    isoutputing,
    createNewChat,
    chatItemClick,
    settingClick,
    setLoading,
  } = props;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // 是否折叠左边菜单
  const [isShowCode, setIsShowCode] = useState(false); // 是否展示代码树
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chatList, setChatList] = useState([]); //聊天列表
  const [currentList, setCurrentList] = useState("" as any);
  const [selectNode, setSelectNode] = useState<TreeData>();
  const { theme, toggleTheme } = useTheme();
  const { userInfo, toggleUserInfo } = useUserInfo();

  useEffect(() => {
    if (router.isReady) {
      const token = localStorage.getItem("token");
      // 路由参数已准备好，可以进行操作
      token && getChatList();
      setCurrentList(router.query.thread_id);
    }
  }, [router]);
  // 获取对话列表
  const getChatList = () => {
    http.get(`/api/v1/chat/chatlist`).then(
      (data: any) => {
        if (data.status) {
          setChatList(data.data);
        } else {
          ShowMessage.error(data.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const deleteChat = (uuid: any) => {
    setLoading(true);
    http
      .delete(`/api/v1/chat/chat/${uuid}`)
      .then((data: any) => {
        if (data.status) {
          getChatList();
        } else {
          ShowMessage.error(data.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  const showCode = () => {
    setIsShowCode(!isShowCode);
  };
  const handleSelect = (node: TreeData) => {
    setSelectNode(node);
    setIsOpen(true);
  };
  return (
    <>
      <div
        className={`${styles.pannel} ${
          isSidebarCollapsed ? styles.collapsed : ""
        }`}
      >
        <div
          className={styles.sidebarControls}
          style={isSidebarCollapsed ? { padding: "10px" } : {}}
        >
          <Tooltip
            placement="bottom"
            title={isSidebarCollapsed ? "展开侧栏" : "收起侧栏"}
          >
            <div className={styles.toggleSidebar} onClick={toggleSidebar}>
              {isSidebarCollapsed ? (
                <svg
                  className={styles.icon}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  className={styles.icon}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </div>
          </Tooltip>
          {!isSidebarCollapsed && (
            <div className={styles.logoContainer}>
              <div className={styles.text_logo}></div>
            </div>
          )}
          {/* 切换项目树按钮 */}
          <div className={styles.btns}>
            {!isSidebarCollapsed && !!codeData.branch_name && (
              <div className={styles.switchBtn} onClick={showCode}>
                <Tooltip
                  placement="bottom"
                  title={!isShowCode ? "目录树视图" : "返回对话列表"}
                >
                  <div>
                    {isShowCode ? (
                      <svg
                        className={styles.icon}
                        viewBox="0 0 1706 1024"
                        fill="currentColor"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="5210"
                        width="32"
                        height="32"
                      >
                        <path
                          d="M1176.50680606 206.4796294H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405887v5.09200598a48.37405887 48.37405887 0 0 0 48.37405887 48.37405887h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 259.94569425v-5.09200598A48.37405887 48.37405887 0 0 0 1176.50680606 206.4796294z m0 254.60030903H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405828v5.09200658a48.37405887 48.37405887 0 0 0 48.37405887 48.37405828h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 514.54600329v-5.09200658A48.37405887 48.37405887 0 0 0 1176.50680606 461.07993843z m0 254.60030844H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405888v5.09200597a48.37405887 48.37405887 0 0 0 48.37405887 48.37405888h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 769.14631172v-5.09200597A48.37405887 48.37405887 0 0 0 1176.50680606 715.68024687zM257.39969097 206.4796294a50.92006157 50.92006157 0 1 0 50.92006216 50.92006157A50.92006157 50.92006157 0 0 0 257.39969097 206.4796294z m0 254.60030903a50.92006157 50.92006157 0 1 0 50.92006216 50.92006157A50.92006157 50.92006157 0 0 0 257.39969097 461.07993843z m0 254.60030844a50.92006157 50.92006157 0 1 0 50.92006216 50.92006216A50.92006157 50.92006157 0 0 0 257.39969097 715.68024687z"
                          p-id="5211"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className={styles.icon}
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="4848"
                        width="24"
                        height="24"
                      >
                        <path
                          d="M705.5 554.47h215a21.44 21.44 0 0 0 21.5-21.24V405.82a21.43 21.43 0 0 0-21.51-21.22h-215A21.43 21.43 0 0 0 684 405.82V435H289V299.65h115.49A21.43 21.43 0 0 0 426 278.42V151a21.43 21.43 0 0 0-21.51-21.23h-301A21.42 21.42 0 0 0 82 151v127.42a21.42 21.42 0 0 0 21.5 21.23h115.57v501.67c0 23.49 19.21 42.47 43 42.47H684V873a21.44 21.44 0 0 0 21.51 21.23h215A21.44 21.44 0 0 0 942 873V745.58a21.44 21.44 0 0 0-21.51-21.24h-215A21.44 21.44 0 0 0 684 745.58v29.2H289V504h395v29.19a21.44 21.44 0 0 0 21.51 21.24z"
                          p-id="4849"
                        ></path>
                      </svg>
                    )}
                  </div>
                </Tooltip>
              </div>
            )}
            <Tooltip placement="bottom" title={"新建对话"}>
              <div
                className={styles.editIcon}
                onClick={() => {
                  setIsShowCode(false);
                  createNewChat();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="icon-xl-heavy"
                >
                  <path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        </div>
        {!isSidebarCollapsed && (
          <div className={styles.contentBox}>
            <div className={styles.sliderline}></div>
            <div className={styles.fileBox}>
              {!isShowCode ? (
                <div className={styles.fileList}>
                  {chatList.map((item: any, index: number) => {
                    return (
                      <div key={index} className={styles.fileItem}>
                        <div
                          onClick={() => {
                            setCurrentList(item.thread_id);
                            chatItemClick(item.thread_id);
                          }}
                          title={item.thread_name ? item.thread_name : "未知"}
                          className={`${styles.fileTitle} ellipsis ${
                            item.thread_id == currentList ? styles.active : ""
                          }`}
                          key={item.uuid}
                        >
                          {item.thread_name ? item.thread_name : "新对话"}
                        </div>
                        <span
                          onClick={() => {
                            deleteChat(item.uuid);
                          }}
                          className={styles.delete}
                        ></span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={styles.repository}>
                  <div className={styles.fullName}>
                    仓库名称：
                    <span title={codeData.repo_full_name}>
                      {codeData.repo_full_name}
                    </span>
                  </div>
                  <div className={styles.branchName}>
                    当前分支：
                    <span title={codeData.branch_name}>
                      {codeData.branch_name}
                    </span>
                  </div>
                  <div className={styles.fileTree}>
                    <CodeTree
                      thread_id={router.query.thread_id as string}
                      branch_name={codeData.branch_name}
                      repo_full_name={codeData.repo_full_name}
                      provider_type={codeData.provider_type}
                      onSelect={handleSelect}
                      treeData={treeData}
                      disabled={isoutputing}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.line}></div>
        <div className={styles.userInfo}>
          <Tooltip placement="top" title={"设置"}>
            <div className={styles.btnBox}>
              <div
                onClick={() => {
                  setIsOpen(false);
                  settingClick();
                }}
                className={`${styles.setting} ${
                  theme === "light" ? styles.settingLight : styles.settingDark
                }`}
              ></div>
            </div>
          </Tooltip>
          <div className={styles.infoContent}>
            <p
              className={styles.text}
              title={userInfo.display_name || userInfo.username}
            >
              {userInfo.display_name || userInfo.username}
            </p>
            <p>|</p>
            <p className={styles.text}>{userInfo.accaccount_type_name}</p>
          </div>

          <Tooltip placement="top" title={"登出"}>
            <div className={styles.btnBox}>
              <div
                onClick={() => {
                  Router.push("/login");
                  toggleUserInfo({});
                  localStorage.removeItem("token");
                }}
                className={`${styles.exitBtn} ${
                  theme == "light" ? styles.exitLight : styles.exitDark
                }`}
              ></div>
            </div>
          </Tooltip>
        </div>
      </div>
      <CodeModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={selectNode?.path || ""}
      >
        <CodePreview
          thread_id={router.query.thread_id as string}
          path={selectNode?.path || ""}
          branch_name={codeData.branch_name}
          repo_full_name={codeData.repo_full_name}
          provider_type={codeData.provider_type}
        />
      </CodeModal>
    </>
  );
}
