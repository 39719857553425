import React, { useEffect, useState } from "react";
import TypingEffect from "../TypingEffect";
import Loading from "../loading";
import http from "../../http";
import { SuffixCorrespondencemap } from "../../utils/SuffixCorrespondencemap";

export default function CodePreview(props: {
  thread_id: string;
  path: string;
  branch_name: string;
  repo_full_name: string;
  provider_type: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  useEffect(() => {
    getCode();
  }, [props]);
  const getCode = () => {
    setIsLoading(true);
    http
      .get(
        `/api/v1/chat/code-content?thread_id=${props.thread_id}&file_path=${props.path}&branch_name=${props.branch_name}&repo_full_name=${props.repo_full_name}&provider_type=${props.provider_type}`
      )
      .then(
        (data: any) => {
          if (data.status) {
            const suffix =
              SuffixCorrespondencemap[
                (props.path.split(".") || []).at(-1) || ""
              ] || "txt";
            const newText =
              suffix !== "markdown"
                ? "```" + suffix + `\n` + data.data.content + "```"
                : `\n` + data.data.content;
            setText(newText || "");
            setIsLoading(false);
          }
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      {isLoading && <Loading></Loading>}
      <TypingEffect text={text} speed={10} />
    </div>
  );
}
