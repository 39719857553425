import { CheckCircleFilled, DownOutlined } from "@ant-design/icons";
import { Divider, Dropdown, MenuProps, Space, theme } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./assistantSelect.module.scss";

export default function AssistantSelect(props: {
  assistantChange: (value: string) => void;
}) {
  const { useToken } = theme;
  const { token } = useToken();
  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const [value, setValue] = useState("0");
  const handleClick = (e: any) => {
    setValue(e.key);
    localStorage.setItem("assistant_id", e.key);
  };
  const menuMap: any = {
    "0": "Eagle",
    "1": "Eagle Review",
  };
  useEffect(() => {
    const assistant_id = localStorage.getItem("assistant_id");
    setValue(assistant_id || value);
  }, []);

  useEffect(() => {
    props.assistantChange(value);
  }, [value]);

  const items: MenuProps["items"] = [
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.assistantItem}>
            <span>Eagle</span>
            <span className={styles.desc}>帮助代码理解和改进代码模型</span>
          </div>
          {value === "0" ? <CheckCircleFilled /> : null}
        </div>
      ),
      key: "0",
      onClick: handleClick,
    },
    {
      label: (
        <div className={styles.menuItem}>
          <div className={styles.assistantItem}>
            <span>Eagle Review</span>
            <span className={styles.desc}>进行审查PR、Issue分析模型</span>
          </div>
          {value === "1" ? <CheckCircleFilled /> : null}
        </div>
      ),
      key: "1",
      onClick: handleClick,
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
        </div>
      )}
    >
      <div className={styles.AssistantSelect}>
        <Space>
          {menuMap[value]}
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
}
