import styles from "../styles/setting.module.scss";
import { useEffect, useState, useRef } from "react";
import http from "../http";
import { useTheme } from "../context/ThemeContext";
import { ShowMessage } from "./ShowMessage/ShowMessage";
import { Button, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";

export default function Setting(props: any) {
  const { visible, userInfo, getInfo, oncancel } = props;
  let [email, setEmail] = useState(userInfo.email);
  const [usernameVal, setUsernameVal] = useState(userInfo.username);
  const [password, setPassword] = useState("");
  const [passwordSure, setPasswordSure] = useState("");
  const [displayName, setDisplayName] = useState(userInfo.display_name);
  const [phone, setPhone] = useState(userInfo.phone_number);
  const [showEdit, setShowEdit] = useState(false);
  const [password_iconshow, setPassword_iconshow] = useState(false);
  const [theme_iconshow, setTheme_iconshow] = useState(false);
  const [code, setCode] = useState("");
  const [changePhone, setChangePhone] = useState(false);
  const userEdit = useRef({} as any);
  const { theme, toggleTheme } = useTheme();
  const [themeVal, setThemeVal] = useState(theme);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (userInfo?.phone_number && token) {
      return () => {
        if (timer.current) {
          clearInterval(timer.current);
        }
      };
    }
  }, []);
  const getCode = () => {
    let number = phone;
    if (!number) {
      ShowMessage.error("请输入手机号");
      return;
    } else if (!/^1[3456789]\d{9}$/.test(number)) {
      ShowMessage.error("请输入正确的手机号");
      return;
    }
    http
      .post("/api/v1/account/code/", { phone_number: number })
      .then((res: any) => {
        if (res.status) {
        } else {
          ShowMessage.error(res.message);
        }
      });
  };
  const saveInfo = () => {
    toggleTheme(themeVal);
    if (password === passwordSure) {
      if (password != "") {
        userEdit.current.password = password;
      }
    } else {
      ShowMessage.error("两次密码不一致");
      return;
    }
    http.put("/api/v1/account/edit", userEdit.current).then((res: any) => {
      if (res.status) {
        setShowEdit(false);
        userEdit.current = {};
        getInfo();
      } else {
        ShowMessage.error(res.message);
      }
    });
  };

  return (
    <div className={styles.wraper} style={visible ? {} : { display: "none" }}>
      <div className={styles.settingHead}>
        <Button
          onClick={() => {
            setShowEdit(false);
            oncancel();
          }}
          type="text"
          icon={<LeftOutlined />}
        >
          返回
        </Button>
      </div>
      <div className={styles.form}>
        <div className={styles.info_part}>
          <div className={styles.info_label}>用户名</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            value={usernameVal || ""}
            onChange={(e) => {
              setUsernameVal(e.currentTarget.value);
              userEdit.current.username = e.currentTarget.value;
            }}
            placeholder="请输入您的用户名"
          />
        </div>
        <div className={styles.info_part}>
          <div className={styles.info_label}>昵称</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            value={displayName || ""}
            onChange={(e) => {
              setDisplayName(e.currentTarget.value);
              userEdit.current.display_name = e.currentTarget.value;
            }}
            placeholder="请输入您的昵称"
          />
        </div>
        <div className={styles.info_part}>
          <div className={styles.info_label}>用户邮箱</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            value={email || ""}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
              userEdit.current.email = e.currentTarget.value;
            }}
            placeholder="请输入您的邮箱"
          />
        </div>
        <div className={styles.info_part}>
          <div className={styles.info_label}>手机号</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            value={phone || ""}
            onChange={(e) => {
              setPhone(e.currentTarget.value);
              setChangePhone(true);
              userEdit.current.phone_number = e.currentTarget.value;
            }}
            placeholder="请输入您的手机号"
          />
        </div>
        {showEdit && changePhone && (
          <div className={styles.info_part}>
            <div className={styles.info_label}>验证码</div>
            <input
              value={code || ""}
              onChange={(e) => {
                setCode(e.currentTarget.value);
                userEdit.current.sms_code = e.currentTarget.value;
              }}
              placeholder="请输入短信验证码"
              type="text"
            />
            <div className={styles.send_code} onClick={() => getCode()}>
              发送验证码
            </div>
          </div>
        )}
        <div className={styles.info_part}>
          <div className={styles.info_label}>密码</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            value={showEdit ? password : "******"}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            placeholder="请输入您的密码"
          />
        </div>
        {showEdit && (
          <div className={styles.info_part}>
            <div className={styles.info_label}>密码确认</div>
            <input
              value={passwordSure || ""}
              onChange={(e) => {
                setPasswordSure(e.currentTarget.value);
              }}
              placeholder="请确认您的密码"
              type={password_iconshow ? "text" : "password"}
            />
            <div
              className={
                password_iconshow
                  ? `${styles.icons_show} ${styles.icons}`
                  : styles.icons
              }
              onClick={() => setPassword_iconshow(!password_iconshow)}
            ></div>
          </div>
        )}
        <div className={styles.info_part}>
          <div className={styles.info_label}>主题</div>
          <input
            disabled={!showEdit}
            className={!showEdit ? styles.info_input : ""}
            readOnly
            value={themeVal == "dark" ? "深色" : "浅色"}
            placeholder="请选择主题"
            onClick={() => showEdit && setTheme_iconshow(!theme_iconshow)}
          />
          <div
            className={
              theme_iconshow
                ? `${styles.icons_show} ${styles.select}`
                : styles.select
            }
            style={showEdit ? { cursor: "pointer" } : { cursor: "default" }}
            onClick={() => showEdit && setTheme_iconshow(!theme_iconshow)}
          ></div>
          {theme_iconshow && (
            <ul className={styles.selectDown}>
              <li
                onClick={() => {
                  setThemeVal("dark");
                  setTheme_iconshow(false);
                }}
              >
                深色
              </li>
              <li
                onClick={() => {
                  setThemeVal("light");
                  setTheme_iconshow(false);
                }}
              >
                浅色
              </li>
            </ul>
          )}
        </div>

        <div className={styles.editBtns}>
          {showEdit ? (
            <>
              <Button
                type="default"
                onClick={() => {
                  setShowEdit(false);
                }}
                block
                size="large"
                className={styles.btn}
              >
                取消
              </Button>
              <Button
                type="primary"
                size="large"
                block
                onClick={saveInfo}
                className={styles.btn}
              >
                保存
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setShowEdit(true);
              }}
              className={styles.saveBtn}
            >
              修改
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
